import * as React from 'react';

import {PageProps} from 'gatsby';
import {QAllImages} from '../components/QAllImages/QAllImages';
import {AllImages} from '../components/QAllImages/__generated__/AllImages';
import {PageHead} from '../components/page-head/PageHead';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImageData } from '../utils/data';
import './ImagePage.scss';
import Icon from './../images/svg/icon.svg';
import {Link} from 'gatsby';
import * as queryString from 'query-string';

type PageContext = {
  name: string;
};

type Props = {pageContext: PageProps & PageContext} & {location: Location};

export default (props: Props & {location: Location}) => {
  const backHrefUnknown: string | string [] | null = queryString.parse(props.location.search)?.back ?? null;
  const backHrefOrNull: string | null = Array.isArray(backHrefUnknown) ? backHrefUnknown[0] : backHrefUnknown;
  const backHref: string = backHrefOrNull === null ? '/' : backHrefOrNull;

  return (
    <>
      <PageHead/>
      <Link className="alexey-photography-link" to="/">
        <Icon className="alexey-photography-icon" height="3em" />
      </Link>
      <Link className="close-link" to={backHref}>
        &#x2715;
      </Link>
      <QAllImages render={(allImages: AllImages) =>
        <GatsbyImage
          className="full-screen-image"
          image={getImageData(props.pageContext.name, allImages)}
          alt={props.pageContext.name}
        />
      }/>
    </>
  );
};
